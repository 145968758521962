import { Badge } from '@shopify/polaris'

import { capitalize } from './strings'

export const USER_COLUMNS = [
  {
    title: 'User',
    key: 'lastName',
    id: 'lastName',
    sortable: true,
  },
  {
    title: 'Email address',
    key: 'email',
    id: 'email',
    sortable: true,
  },
  {
    title: 'Access level',
    key: 'accessLevel',
    id: 'accessLevel',
    sortable: false,
  },
  {
    title: 'Last used',
    key: 'lastRequestAt',
    id: 'lastRequest',
    sortable: true,
  },
  {
    title: 'Status',
    key: 'status',
    id: 'status',
    sortable: true,
  },
]

export const userAccessLevelFromRoles = (roles) => {
  if (!roles) return 0

  const _roles = Array.isArray(roles) ? roles : [roles]

  if (_roles.includes('ADMIN')) {
    return 4
  } else if (_roles.includes('ACCOUNT_ADMIN')) {
    return 3
  } else if (_roles.includes('MANAGER')) {
    return 2
  } else if (_roles.includes('SUPERVISOR')) {
    return 1
  }
  return 0
}

export const accessLevelLabel = (accessLevel) => {
  switch (accessLevel) {
    case 1:
      return 'Level 1 (Novice Manager)'
    case 2:
      return 'Level 2 (Salaried Manager)'
    case 3:
      return 'Level 3 (HR and Account Lead)'
    case 4:
      return 'Level 4 (Administrator)'
    default:
      return 'None'
  }
}

export const UserStatusBadge = ({ status }) => {
  let badgeColor = 'success'
  if (status === 'invited') badgeColor = 'info'
  if (status === 'removed') badgeColor = 'critical'
  if (status === 'inactive') badgeColor = 'new'
  return <Badge status={badgeColor}>{capitalize(status)}</Badge>
}

export const userActions = ({
  currentUser,
  associate,
  occurrence,
}) => {
  const { roles } = currentUser
  const canDeleteOccurrence =
    occurrence.deletedAt == null
    && currentUser.roles.some((role) =>
      associate.account.occurrenceDeleteLevels.includes(role)
    )
  const canUpdateExpiresAt =
    associate.account.allowViolationExpiryOverride
    && (roles.includes('ADMIN') || roles.includes('ACCOUNT_ADMIN'))
  const canUpdateCounselingLevel =
    associate.account.allowOccurrenceCounselingLevelChange
    && (roles.includes('ADMIN') || roles.includes('ACCOUNT_ADMIN'))

  return {
    canDeleteOccurrence,
    canUpdateCounselingLevel,
    canUpdateExpiresAt,
  }
}

export const getAssociatedAccountIdsByUser = (user) => {
  const accountIds = new Set();

  (user?.adminOnAccounts || []).forEach((account) => {
    accountIds.add(account.id);
  });

  (user?.managedCostCenters || []).forEach((costCenter) => {
    accountIds.add(costCenter.account.id);
  });

  (user?.adminOnCostCenters || []).forEach((costCenter) => {
    accountIds.add(costCenter.account.id);
  });

  return Array.from(accountIds);
}