import { counselingLabels } from '../helpers/associates'

const CLASS_A_WORK_RULES = [
  `1. Theft, dishonesty, or misappropriation of Company funds`,
  `2. Recording false information, including but not limited to, tampering with your own or another Associate’s time record`,
  `3. Deliberately entering false information on any reports or records`,
  `4. Deliberate misrepresentation of information when seeking employment with the Company`,
  `5. Deliberate misuse or waste of Company cash, P-cards, merchandise, or product`,
  `6. Removal of property, personal or otherwise, belonging to the Company, another Associate, customers, or clients without permission`,
  `7. Deliberate misuse or removal of Company or client property, tools, equipment, uniforms, etc. from Company and/or client premises`,
  `8. Misuse of any Company or client issued identification material (i.e., security pass or badge)`,
  `9. Reporting for work while under the influence of an illegal drug or alcohol`,
  `10. Possession, use, sale, or distribution of illegal drugs or other controlled substances during working hours or while on client or Company business or premises (including Company owned or leased vehicles, as well as Associates’ personal vehicles used for business purposes)`,
  `11. Possession of firearms or any other dangerous weapons or instruments, concealed or otherwise, on Company or client premises, including in vehicles, lockers, personal belongings, etc. unless permitted by applicable state or local law`,
  `12. Engaging in physical violence or instigating a fight during working hours or on Company and/or client premises`,
  `13. Willful destruction of Company or client property`,
  `14. Indecent behavior during working hours`,
  `15. Taking or giving bribes`,
  `16. Failure to protect Company assets through gross negligence or willful misconduct (i.e., loss of funds, product, or business)`,
  `17. Failure to safeguard Company technology assets and services (i.e., loss or theft of equipment, or security breaches due to gross negligence)`,
  `18. Deliberate misuse or unauthorized disclosure of confidential business information not otherwise made available to persons or firms outside of the Company`,
  `19. Use of Company resources and capital for personal financial gain`,
  `20. Failure to comply with and follow the Company’s Cash Handling Policy and all other financially-related policies and procedures`,
  `21. Unauthorized use of a Company or client vehicle`,
  `22. Operating a Company vehicle without having the current license(s) as required by federal, state, or local law`,
  `23. Operating a Company vehicle while the Associate’s license is suspended, revoked, or cancelled by any state`,
  `24. Operating a Company vehicle when the Associate has lost the right to operate a vehicle in any state, has been disqualified from operating a vehicle in any state, or does not have the correct license class or license endorsements as required by federal, state, or local law.`,
  `25. Discriminatory practices such as disparate treatment, harassment, exclusion, or ridicule of individuals based on sex, sexual orientation, gender identity, race, religion, color, creed, disability, age, marital status, pregnancy, child birth or any related condition, national origin, citizenship status, veteran status, genetic information, protected concerted activity, or any other classification protected by applicable law (extends to Company Associates, clients, suppliers, or vendors)`,
  `26. Retaliatory practices such as, but not limited to, harassment, exclusion, demotion, termination, threats, reduced hours, or ridicule due to an Associate’s filing of a complaint and/or participation in an investigation, proceeding, or hearing`,
  `27. Allowing non-Associates to access a Company and/or client building or property for any reason unless such access is required by law, authorized by the Company, or in the case of a client’s property or building, is authorized by the client`,
  `28. Accessing interior areas of a Company building and other working areas on a Company property for any reason while off duty`,
  `29. Accessing a client property or building while off duty if the client maintains a lawful work rule barring off-duty Associates of onsite contractors from accessing its property and/or building`,
  `30. Physically or verbally threatening fellow Associates with violence`,
]

const CLASS_B_WORK_RULES = [
  `1. Refusal to perform any reasonable job or work assignment given by the Associate’s supervisor or other members of management when the Associate clearly understands the assignment and has been warned that their refusal may result in progressive counseling, up to and including discharge. (This behavior is also known as insubordination.)`,
  `2. Swearing or use of other abusive language`,
  `3. Making or writing maliciously false or knowingly slanderous statements concerning any Associate, the Company, client, or Company or client products (this includes content posted on Social Media)`,
  `4. Making any public statement for or on behalf of the Company without prior authorization that puts the Company at reputational risk`,
  `5. Misuse or unauthorized disclosure of confidential business information not otherwise made available to persons or firms outside of the Company`,
  `6. Failure to immediately report any work-related accidents`,
  `7. Reckless or dangerous conduct, including horseplay, during working hours or in work areas`,
  `8. Violating any safety and sanitation rules or practices, including failure to wear appropriate personal protective equipment (including, but not limited to, cut gloves, slip resistant shoes, food handling gloves, chemical resistant gloves, goggles, or car safety belts when driving a Company vehicle or other vehicle used in the course and scope of Company business), or engaging in any behavior that may create an unsafe workplace or safety hazard`,
  `9. Violations of Compass Group or client building security procedures`,
  `10. Serious, unlawful, improper, or wrongful conduct in connection with employment. (This behavior is also known as gross neglect of duty.)`,
  `11. Attempting to enter, entering, or assisting any person to enter the Company’s and/or client’s restricted areas without proper authorization`,
  `12. Being present for any reason in interior work areas before the start or after the finish of an Associate’s work day, or after the Associate’s separation from employment`,
  `13. Absence, lateness, or leaving early without notifying management or without having a reasonable excuse`,
  `14. Sleeping or dozing on the job`,
  `15. Gambling on the job`,
  `16. Soliciting of any type on Company and/or client premises during the working time of the Associate being solicited, or the Associate who is soliciting; This excludes Meal Periods and Rest Breaks`,
  `17. Engaging in any conduct outside the workplace – other than protected concerted activities – which is harmful to the interest or reputation of the Company, including, but not limited to, criminal, illegal, and unlawful acts`,
  `18. Losing or misplacing keys or other Company equipment while under an Associate’s direct responsibility`,
  `19. Unauthorized review of documents left on client and/or Company work spaces`,
  `20. Violations of the Company’s Fair Treatment Policy`,
  `21. Smoking or chewing tobacco, vaping, or use of e-cigarettes (or other similar devices) in an unauthorized area, when handling food, when in contact with customers, or when in food production or dish room areas`,
]

const CLASS_C_WORK_RULES = [
  `1. Straying from one’s work area during working hours for any unauthorized reason, excluding Meal Periods, Rest Breaks, and using restrooms`,
  `2. An Associate’s loss of interest in or inattention to their work duties`,
  `3. Failure to meet reasonable standards of efficiency`,
  `4. Neglect of job duties and responsibilities where gross neglect is not involved`,
  `5. Failure to keep accurate records`,
  `6. Unauthorized use of electronic systems or equipment, including, but not limited to, computers and telephones belonging to the Company and/or client`,
  `7. Excessive and/or inappropriate use of personal technology, including, but not limited to, cell phones, headphones, etc. during working hours (excluding Meal Periods and Rest Breaks)`,
  `8. Failure to observe the Company dress code, uniform policy, cleanliness, personal hygiene, personal habits, personal appearance, or other requirements established by governmental or regulatory agencies, or by the Company`,
  `9. Failure to follow account-established call-out procedures for excused or unexcused absences, lateness, or leaving early.`,
]

export const WORK_RULE_DEFAULTS = [
  ...CLASS_A_WORK_RULES.map((rule) => ({ class: 'a', name: rule })),
  ...CLASS_B_WORK_RULES.map((rule) => ({ class: 'b', name: rule })),
  ...CLASS_C_WORK_RULES.map((rule) => ({ class: 'c', name: rule })),
]

export const OCCURRENCE_TYPE_DEFAULTS = [
  {
    name: 'Unexcused absence',
    key: 'unexcused_absence',
    points: 1,
    enabled: true,
  },
  {
    name: 'Lateness',
    key: 'lateness',
    points: 0.5,
    enabled: true,
  },
  {
    name: 'Lateness (120+ minutes)',
    key: 'lateness120',
    points: 1,
    enabled: false,
  },
  {
    name: 'Early-Out',
    key: 'early_out',
    points: 0.5,
    enabled: true,
  },
  {
    name: 'Failure to follow Meal Period and Rest Break protocols',
    key: 'failure_to_follow_meal_protocols',
    points: 0.5,
    enabled: true,
  },
  {
    name: 'No Call, No Show',
    key: 'no_call_no_show',
    points: 1,
    enabled: true,
  },
  {
    name: 'Unauthorized absence from station',
    key: 'unauthorized_absence_from_station',
    points: 0.5,
    enabled: false,
  },
]

export const severityOfCounseling = ({ account, counselingType }) => {
  if (counselingType === COUNSELING_TYPES.VERBAL_WARNING) {
    return -1
  }
  return (
    Object.keys(counselingLabels(account)).findIndex(
      (key) => key === counselingType
    ) || 0
  )
}

export const COUNSELING_TYPES = {
  FIRST_WARNING: 'firstWarning',
  SECOND_WARNING: 'secondWarning',
  VERBAL_WRITTEN_WARNING: 'verbalWrittenWarning',
  WRITTEN_WARNING: 'writtenWarning',
  FINAL_WARNING: 'finalWarning',
  TERMINATION: 'termination',
  PROBATION_FINAL_WARNING: 'probationFinalWarning',
  PROBATION_TERMINATION: 'probationTermination',
  NCNS_PROBATION_FINAL_WARNING: 'ncnsProbationFinalWarning',
  NCNS_PROBATION_TERMINATION: 'ncnsProbationTermination',
  NCNS_WRITTEN_WARNING: 'ncnsWrittenWarning',
  NCNS_FINAL_WARNING: 'ncnsFinalWarning',
  NCNS_TERMINATION: 'ncnsTermination',
  VERBAL_WARNING: 'verbalWarning',
  NOTE_TO_FILE: 'noteToFile',
}

export const COUNSELING_THRESHOLD_DEFAULTS = {
  [COUNSELING_TYPES.VERBAL_WRITTEN_WARNING]: 4,
  [COUNSELING_TYPES.WRITTEN_WARNING]: 5,
  [COUNSELING_TYPES.FINAL_WARNING]: 6,
  [COUNSELING_TYPES.TERMINATION]: 7,
  [COUNSELING_TYPES.PROBATION_FINAL_WARNING]: 2,
  [COUNSELING_TYPES.PROBATION_TERMINATION]: 3,
  [COUNSELING_TYPES.NCNS_PROBATION_FINAL_WARNING]: 0,
  [COUNSELING_TYPES.NCNS_PROBATION_TERMINATION]: 1,
  [COUNSELING_TYPES.NCNS_WRITTEN_WARNING]: 0,
  [COUNSELING_TYPES.NCNS_FINAL_WARNING]: 1,
  [COUNSELING_TYPES.NCNS_TERMINATION]: 2,
}

export const DEFAULT_AREA_CODES = {
  AL: 205,
  AK: 907,
  AZ: 602,
  AR: 501,
  CA: 213,
  CO: 303,
  CT: 203,
  DE: 302,
  FL: 305,
  GA: 404,
  HI: 808,
  ID: 208,
  IL: 217,
  IN: 219,
  IA: 319,
  KS: 316,
  KY: 270,
  LA: 225,
  ME: 207,
  MD: 240,
  MA: 617,
  MI: 231,
  MN: 218,
  MS: 228,
  MO: 314,
  MT: 406,
  NE: 308,
  NV: 702,
  NH: 603,
  NJ: 201,
  NM: 505,
  NY: 212,
  NC: 252,
  ND: 701,
  OH: 216,
  OK: 405,
  OR: 503,
  PA: 215,
  RI: 401,
  SC: 803,
  SD: 605,
  TN: 423,
  TX: 210,
  UT: 385,
  VT: 802,
  VA: 276,
  WA: 206,
  WV: 304,
  WI: 262,
  WY: 307,
  DC: 202,
}

export const DEFAULT_SUPERVISOR_JOB_TITLES = [
  'SUPV, PRODUCTION-HRLY',
  'SUPV, DINING/BAR',
  'SUPV, SHIFT-HRLY',
  'SUPV, COOK-HRLY',
  'SUPV, FOH LEAD',
  'CHEF, SOUS',
  'Food Production Leader',
  'Food Service Operations Coord',
  'Food Service Supervisor',
  'Sr Cook',
  'Building and Grounds Coord',
  'Sr Food Serv Asst',
  'COORD, RETAIL',
  'MGR, C-STORE',
  'SUPERVISOR',
]
