import { isUnionized } from './associates'

export const ACCOUNTS = {
  COLGATE: '0d0fa287-6ab9-4ab2-93a0-ad709da50a76',
  CATHOLIC_U_OF_AMERICA: '7e5c4bf6-b829-4d2e-a687-ef6ebe9a0686',
  DEPAUL: '694a2960-e433-4c90-be5c-27ea50ebff72',
  DEMO_ACCOUNT_1: 'e6c0a057-1500-42f1-bddb-5e351e108597',
  DEMO_ACCOUNT_2: '57ca907c-7c2f-4569-843e-9953ea1e7f29',
  DEV_DEMO_ACCOUNT: 'e5967448-c39c-4360-bac1-47c35be5b3c9',
  DEV_DEMO_NON_UNION: 'a33582a0-288a-472f-a9d9-6ae4b68289e1',
  DEV_TRACKPATH_U: '20e3b96f-10a7-49d9-8b7d-752842037c4c',
  DEV_MIAMI_U_OHIO: '33e0082b-d815-4c2a-9928-5a7112e4268e',
  DEV_NORTHWESTERN: '6c30bf2e-4b0a-4079-8ab4-534c772e7a5b',
  DEV_NORTHWESTERN_TEST: '51926288-0930-4b7b-a273-9c88edd7fef5',
  DEV_NORTHWESTERN_JASON: '982f0e40-0f7b-4918-b215-5368095b72aa',
  DEV_MINNESOTA: '7d0deb04-01ac-447d-bc3e-1abcca1a7dbf',
  DEV_DEMO_JASON: '8632176c-4e35-47ee-8b1c-314b73058d17',
  HOUSTON: 'c93efe08-2aa8-442e-991b-c8c9f5cc85e9',
  MIAMI_U_OHIO: 'f2c02b01-d09f-4643-b0c2-e7f89f4eabd0',
  MINNESOTA: 'bc8e6e18-d857-4875-bfe3-89466fd49075',
  NORTHWESTERN: '547853e5-cc55-4316-93ef-d277074ca9c9',
  TAMU: '8a022163-1a5b-4d24-ac83-33f0e26e1c43',
  DEMO: '2a8d9268-4832-429f-8bf1-b5a5d759df49',
  BUFFALO_STATE: '1862afc2-8f13-4981-a550-a47a29054fd4',
  GWU: '4ce5345a-7f39-4b2b-95cf-2c99e898147c',
  UTD: '1c5efc77-a5d7-4c09-a8ed-f11fe07babc2',
  BOISE: '40894add-d3ec-41ab-ab6c-4901714c35e2',
  MIAMI: '8f52e71a-652d-4932-9369-462a52a5d81f',
}

export const hasSickDays = (account) => {
  return [
    ACCOUNTS.DEV_TRACKPATH_U,
    ACCOUNTS.DEV_NORTHWESTERN_TEST,
    ACCOUNTS.DEV_NORTHWESTERN_JASON,
  ].includes(account?.id)
}

export const hasDigitalSigning = (account) => {
  return [
    // ACCOUNTS.DEMO,
    // ACCOUNTS.DEV_DEMO_ACCOUNT,
    // ACCOUNTS.DEV_NORTHWESTERN,
    // ACCOUNTS.DEV_DEMO_JASON,
    // ACCOUNTS.DEV_NORTHWESTERN_JASON,
  ].includes(account?.id)
}

export const hasOtShifts = (account) => {
  return [
    ACCOUNTS.DEV_DEMO_ACCOUNT,
    ACCOUNTS.DEV_NORTHWESTERN,
    ACCOUNTS.DEV_TRACKPATH_U,
    ACCOUNTS.DEV_NORTHWESTERN_TEST,
  ].includes(account?.id)
}

export const hasAvailableDayBuckets = (account) => {
  return [
    ACCOUNTS.COLGATE,
    ACCOUNTS.CATHOLIC_U_OF_AMERICA,
    ACCOUNTS.DEV_DEMO_ACCOUNT,
    ACCOUNTS.DEV_NORTHWESTERN,
    ACCOUNTS.DEPAUL,
    ACCOUNTS.NORTHWESTERN,
  ].includes(account?.id)
}

export const availableDayBucketTypes = (associate) => {
  const { account } = associate
  if (!hasAvailableDayBuckets(account)) {
    return []
  }
  if (
    [ACCOUNTS.NORTHWESTERN, ACCOUNTS.DEV_DEMO_ACCOUNT].includes(account?.id)
  ) {
    return ['floatingHoliday']
  }
  if ([ACCOUNTS.COLGATE].includes(account?.id)) {
    return isUnionized(associate) ? ['vacation', 'sick'] : []
  }
  return ['vacation', 'sick', 'floatingHoliday']
}

export const isMinnesota = (account) => {
  return [ACCOUNTS.MINNESOTA].includes(account?.id)
}

export const isMiamiUOhio = (account) => {
  return [ACCOUNTS.DEV_MIAMI_U_OHIO, ACCOUNTS.MIAMI_U_OHIO].includes(
    account?.id
  )
}

export const hasOccurrenceImport = (account) => {
  return [
    ACCOUNTS.DEV_NORTHWESTERN_TEST,
    ACCOUNTS.DEV_DEMO_NON_UNION,
  ].includes(account?.id)
}

export const hideCounselingSheets = (associate) => {
  const { account } = associate
  if (
    [
      ACCOUNTS.DEV_MIAMI_U_OHIO,
      ACCOUNTS.MIAMI_U_OHIO,
    ].includes(account?.id)
    && isUnionized(associate)
  ) {
    return true
  }
  return false
}

export const disableLevel1Users = (account) => {
  return [
    ACCOUNTS.DEV_NORTHWESTERN,
    ACCOUNTS.DEV_NORTHWESTERN_TEST,
    ACCOUNTS.NORTHWESTERN,
  ].includes(account?.id)
}

export const printPartialAssociateSummary = (account) => {
  return [
    ACCOUNTS.DEV_TRACKPATH_U,
    ACCOUNTS.NORTHWESTERN,
    ACCOUNTS.DEV_DEMO_ACCOUNT,
    ACCOUNTS.DEV_NORTHWESTERN,
  ].includes(account?.id)
}

export const FEATURES = {
  OCCURRENCES: 'OCCURRENCES',
  NO_CALL_NO_SHOWS: 'NO_CALL_NO_SHOWS',
  WORK_RULES_VIOLATIONS: 'WORK_RULES_VIOLATIONS',
  EXCUSED_ABSENCES: 'EXCUSED_ABSENCES',
}

export const hasFeature = ({ account, feature }) => {
  return Boolean(account && !account.hiddenFeatures?.includes(feature))
}

export const hasPauseCounselingFeature = (account) => {
  return [
    ACCOUNTS.DEV_NORTHWESTERN_JASON,
    ACCOUNTS.DEV_NORTHWESTERN,
    ACCOUNTS.DEV_NORTHWESTERN_TEST,
    ACCOUNTS.NORTHWESTERN,
    '8632176c-4e35-47ee-8b1c-314b73058d17',
  ].includes(account?.id)
}

export const disableViolationEmails = ({ account, user }) => {
  if (account && [ACCOUNTS.NORTHWESTERN].includes(account?.id)) {
    return true
  } else if (user && ['nadeem.zafar@compass-usa.com'].includes(user?.email)) {
    return true
  }
  return false
}

export const disabledLevel1Users = (account) => {
  return [ACCOUNTS.NORTHWESTERN, ACCOUNTS.DEV_NORTHWESTERN_TEST].includes(
    account?.id
  )
}

export const notifyLevel3Users = (account) => {
  return [ACCOUNTS.BOISE, ACCOUNTS.UTD].includes(account?.id)
}

export const hasNoteToFileWrv = (account) => {
  return [ACCOUNTS.DEV_NORTHWESTERN_JASON, ACCOUNTS.UTD].includes(account?.id)
}
